import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';

import { usePatients } from '../../hooks/use-patients.hook';

import { FormControl } from '../../components/form-control.component';
import { Select } from '../../components/select.component';

import { FooterNavigation } from '../../components/footer-navigation.component';
import { savePatient } from '../../services/patients.service';



import { setRegistration } from '../../states/registrations.slice';
import { setSelectedPatient, setErrors, serializePatientPayload } from '../../states/patients.slice';

import { RegisterLayout } from '../../components/register-layout.component';

import { EditPatientInfo } from '../../components/edit-patient-info.component';

import { convertDate } from '../../utils/convertDate';
import { getFullNameKanji } from '../../utils/patient-info.format';

export default function Index() {
    const { navigate, t } = useI18next();

    const { patients } = usePatients();
    const dispatch = useDispatch();
    const [ patientId, setPatientId ] = useState('');
    const { selectedPatient } = useSelector(s => s.patients);
    
    const setPatient = useCallback(patientId => {
        setPatientId(patientId);
        dispatch(setErrors(null));
        
        if(patientId === '') {
            dispatch(setSelectedPatient({}));
            return;
        }

        if(patientId === '0') {
            dispatch(serializePatientPayload(setSelectedPatient({
                isNew: true,
                companyName: '',
                firstName: '',
                firstNameJa: '',
                lastName: '',
                lastNameJa: '',
                gender: '',
                phoneNumber: '',
                emailAddress: '',
                dateOfBirth: { year: '', month: '', day: '', date: null },
                addressOne: '',
                addressTwo: '',
                addressThree: '',
                city: '',
                postalCode: '',
                prefectureName: '',
                identificationExpirationDate: { year: '', month: '', day: '' },
                identificationUploadId: '',
                policiesAccepted: true,
            })));
            return;
        }
        
        const patient = patients.find(p => p.id === Number(patientId));
        
        // Now we don't have to deal with timezone
        const [ year, month, day ] = patient?.dateOfBirth ? patient?.dateOfBirth?.split('-') : [];

        const date = convertDate(patient?.dateOfBirth);

        const [ idYear, idMonth, idDay ] = patient.identificationExpirationDate ? patient.identificationExpirationDate?.split('-') : [];
        const idDate = patient.identificationExpirationDate ? convertDate(patient.identificationExpirationDate) : null;

        dispatch(serializePatientPayload(setSelectedPatient({
            patientId: patientId,
            isNew: false,
            isDefault: patient.isDefault || false,
            companyName: patient.companyName || '',
            firstName: patient.firstName || '',
            firstNameJa: patient.firstNameJa || '',
            lastName: patient.lastName || '',
            lastNameJa: patient.lastNameJa || '',
            gender: patient.gender || '',
            phoneNumber: patient.phoneNumber || '',
            emailAddress: patient.emailAddress || '',
            dateOfBirth: { year, month, day, date },
            addressOne: patient.addressOne || '',
            addressTwo: patient.addressTwo || '',
            addressThree: patient.addressThree || '',
            city: patient.city || '',
            postalCode: patient.postalCode || '',
            prefectureName: patient.prefectureName || '',
            identificationExpirationDate: { year: idYear, month: idMonth, day: idDay, date: idDate },
            identificationUpload: patient?.identificationUpload || '',
            policiesAccepted: true,
            organization: patient.organization || null
         })));
    }, [ patients, selectedPatient ]);
    
    const onNextClick = useCallback(e => {
        dispatch(setErrors({}));
        savePatient(selectedPatient).then(response => {
            dispatch(setRegistration(response.data.registration));
            navigate('/register/scan');
        })
        .catch(error => dispatch(setErrors(error.response.data.message)));
    }, [ selectedPatient, dispatch, navigate ]);
    
    

    return <RegisterLayout className="create-account narrow-nav" backLocation="/register">
        <div className="section section--intro">
            <h1 className="t-h2">{ t('Tester’s information') }</h1>
            <FormControl label={t('Who is tested?')} style={{ paddingBottom: '27px', borderBottom: '1px solid #DBDCE1'}}>
                <Select className="hap-select desktop-half" name="patient" value={patientId} onChange={e => setPatient(e.target.value)}>
                    <option value="">{ t('Select') }</option>
                    { patients.map(patient => <option key={`patient-${patient.id}`} value={patient.id}>{getFullNameKanji(patient)}</option>)}
                    <option value="0">{ t('Create new') }</option>
                </Select>
            </FormControl>
        </div>
        <div className="section section--register-form">
            { patientId && <EditPatientInfo /> }
        </div>
        <FooterNavigation 
            steps={5} 
            activeStep={1}
            label={t('Next')} 
            onClick={onNextClick}
            disabled={patientId === ''}
        />
    </RegisterLayout>;
}